<template>
    <div class="ol-wrapper ol-wrapper--small">
        <div class="ol-checkout-content__success">
            <div class="ol-checkout-content__success-time">
                <h3>Selesaikan Pembayaran Sebelum</h3>
                <h4>{{order.faspay_expired_date_indonesia}}</h4>
            </div>
            <div class="ol-checkout-content__success-methode">
                <h3>{{order.faspay_code == 706 || order.faspay_code == 707 ? 'Kode Pembayaran':'Nomor Virtual Account'}}</h3>
                <figure>
                    <img :src="order.r_uni_gen_payment_faspay.icon_url" :alt="order.r_uni_gen_payment_faspay.name" :title="order.r_uni_gen_payment_faspay.name" style="height:30px!important">
                </figure>
                <div v-if="order.faspay_code == 711">
                    <h4 class="ol-checkout-content__success-barcode">
                        <img :src="order.faspay_qr_code" alt="Barcode" title="Barcode">
                    </h4>

                    <div class="ol-checkout-content__success-qris">
                        <p>Berlaku hingga 12 Apr 2021, 15:04 WIB</p>
                        <p>Menerima Pembayaran melalui aplikasi dibawah ini</p>
                        <img :src="order.r_uni_gen_payment_faspay.icon_url" :alt="order.r_uni_gen_payment_faspay.name" :title="order.r_uni_gen_payment_faspay.name">
                        <p>Dan merchant lain yang menerima pembayaran QRIS</p>
                    </div>
                </div>
                <h4 v-else> <span id="span_trx_id">{{ order.faspay_trx_id }}</span> <a @click="copyNumber('#span_trx_id')"> <i class="ol-fa fas fa-copy"></i> </a> </h4>
            </div>
            <div class="ol-checkout-content__success-total">
                <h3>Total Pembayaran</h3>
                <h4> <span id="span_total_bill">{{ order.total_rp }}</span> <a @click="copyNumber('#span_total_bill')"> <i class="ol-fa fas fa-copy"></i></a></h4>
            </div>
            <div class="ol-checkout-content__success-detail-wrapper">
                <h3>Cara Pembayaran</h3>

                <!-- ShopeePay APP -->
                <ol v-if="order.faspay_code == 713" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">QRIS Mobile Banking atau E-Money lainnya <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Buka aplikasi Shopee</li>
                                        <li>Klik logo "Scan"</li>
                                        <li>Scan QR Code</li>
                                        <li>Klik tombol "Bayar Sekarang"</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </li>

                </ol>

                <!-- ShopeePay QRIS -->
                <ol v-if="order.faspay_code == 711" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">QRIS Mobile Banking atau E-Money lainnya <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Buka aplikasi mobile banking atau e-money</li>
                                        <li>Klik logo "Pay" atau "Scan"</li>
                                        <li>Scan QR Code</li>
                                        <li>Klik tombol "Pay" atau "Bayar"</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </li>

                </ol>

                <!-- Indomaret -->
                <ol v-if="order.faspay_code == 706" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Indomaret <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Catat dan simpan kode pembayaran Indomaret Anda, yaitu : &nbsp;{{ order.faspay_trx_id }}</li>
                                        <li>Datangi kasir Indomaret terdekat dan beritahukan pada kasir bahwa Anda ingin melakukan pembayaran "MORE".</li>
                                        <li>Beritahukan kode pembayaran Indomaret Anda pada kasir dan silahkan lakukan pembayaran Anda senilai {{ order.total_rp }}</li>
                                        <li>Simpan struk pembayaran Anda sebagai tanda bukti pembayaran yang sah."</li>
                                    </ol>
                                </div>
                            </div>

                        </div>
                    </li>
                </ol>

                <!-- Alfamart -->
                <ol v-if="order.faspay_code == 707" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Alfamart <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Catat dan simpan kode pembayaran Alfamart Anda, yaitu : &nbsp;{{ order.faspay_trx_id }}</li>
                                        <li>Datangi kasir Alfamart terdekat dan beritahukan pada kasir bahwa Anda ingin melakukan "pembayaran Eforia Furnitur".</li>
                                        <li>Beritahukan kode pembayaran Alfamart Anda pada kasir dan silahkan lakukan pembayaran Anda senilai {{ order.total_rp }}</li>
                                        <li>Simpan struk pembayaran Anda sebagai tanda bukti pembayaran yang sah."</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Alfamidi <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Catat dan simpan kode pembayaran Alfamidi Anda, yaitu : &nbsp;{{ order.faspay_trx_id }}</li>
                                        <li>Datangi kasir Alfamidi terdekat dan beritahukan pada kasir bahwa Anda ingin melakukan "pembayaran Eforia Furnitur".</li>
                                        <li>Beritahukan kode pembayaran Alfamidi Anda pada kasir dan silahkan lakukan pembayaran Anda senilai {{ order.total_rp }}</li>
                                        <li>Simpan struk pembayaran Anda sebagai tanda bukti pembayaran yang sah."</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Alfa Express <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Catat dan simpan kode pembayaran Alfa Express Anda, yaitu : &nbsp;{{ order.faspay_trx_id }}</li>
                                        <li>Datangi kasir Alfa Express terdekat dan beritahukan pada kasir bahwa Anda ingin melakukan "pembayaran Eforia Furnitur".</li>
                                        <li>Beritahukan kode pembayaran Alfa Express Anda pada kasir dan silahkan lakukan pembayaran Anda senilai {{ order.total_rp }}</li>
                                        <li>Simpan struk pembayaran Anda sebagai tanda bukti pembayaran yang sah."</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title"> DanDan <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Catat dan simpan kode pembayaran DanDan Anda, yaitu : &nbsp;{{ order.faspay_trx_id }}</li>
                                        <li>Datangi kasir DanDan terdekat dan beritahukan pada kasir bahwa Anda ingin melakukan "pembayaran Eforia Furnitur".</li>
                                        <li>Beritahukan kode pembayaran DanDan Anda pada kasir dan silahkan lakukan pembayaran Anda senilai {{ order.total_rp }}</li>
                                        <li>Simpan struk pembayaran Anda sebagai tanda bukti pembayaran yang sah."</li>
                                    </ol>
                                </div>
                            </div>

                        </div>

                    </li>

                </ol>

                <!-- MANDIRI -->
                <ol v-if="order.faspay_code == 802" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Mandiri <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Catat kode pembayaran yang anda dapat</li>
                                        <li>Gunakan ATM Mandiri untuk menyelesaikan pembayaran</li>
                                        <li>Masukkan PIN anda</li>
                                        <li>Pilih 'BAYAR/BELI'</li>
                                        <li>Pilih LAINNYA</li>
                                        <li>Cari pilihan MULTI PAYMENT</li>
                                        <li>Masukkan kode perusahaan 88308</li>
                                        <li>Masukkan kode pelanggan&nbsp;{{ order.faspay_trx_id }}</li>
                                        <li>Masukkan Jumlah Pembayaran sesuai dengan Jumlah Tagihan anda kemudian tekan 'Benar'</li>
                                        <li>Pilih Tagihan Anda jika sudah sesuai tekan YA</li>
                                        <li>Konfirmasikan tagihan anda apakah sudah sesuai lalu tekan YA</li>
                                        <li>Harap Simpan Struk Transaksi yang anda dapatkan</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Internet Banking <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Pada halaman utama pilih mneu BAYAR</li>
                                        <li>Pilih submenu MULTI PAYMENT</li>
                                        <li>Cari Penyedia Jasa 'FASPAY'</li>
                                        <li>Masukkan Jumlah Pembayaran sesuai dengan Jumlah Tagihan anda</li>
                                        <li>Pilih LANJUTKAN</li>
                                        <li>Pilih Tagihan Anda jika sudah sesuai tekan LANJUTKAN</li>
                                        <li>Transaksi selesai, jika perlu CETAK hasil transaksi anda</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Prima <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukan PIN</li>
                                        <li>Pilih menu TRANSAKSI LAINNYA</li>
                                        <li>Pilih menu KE REK BANK LAIN</li>
                                        <li>Masukkan kode sandi Bank Mandiri (008) kemudian tekan BENAR</li>
                                        <li>Masukkan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, dan tekan BENAR</li>
                                        <li>Masukkan jumlah pembayaran sesuai dengan yang ditagihkan dalam halaman konfirmasi</li>
                                        <li>Pilih BENAR untuk menyetujui transaksi tersebut</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Bersama <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan PIN</li>
                                        <li>Pilih TRANSAKSI</li>
                                        <li>Pilih Menu KE REK BANK LAIN</li>
                                        <li>Masukkan kode sandi Bank Mandiri (008) diikuti dengan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, dan tekan BENAR</li>
                                        <li>Masukkan jumlah pembayaran sesuai dengan yang ditagihkan dalam halaman konfirmasi</li>
                                        <li>Pilih BENAR untuk menyetujui transaksi tersebut</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Mandiri Online <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Login Mandiri Online dengan memasukkan USERNAME dan PASSWORD</li>
                                        <li>Pilih menu PEMBAYARAN</li>
                                        <li>Pilih menu MULTI PAYMENT</li>
                                        <li>Cari Penyedia Jasa 'FASPAY'</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT&nbsp;{{ order.faspay_trx_id }}&nbsp;dan nominal yang akan dibayarkan, lalu pilih Lanjut</li>
                                        <li>Setelah muncul tagihan, pilih KONFIRMASI</li>
                                        <li>Masukkan PIN/ CHALLENGE CODE TOKEN</li>
                                        <li>Transaksi selesai, simpan bukti bayar anda</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </li>

                </ol>
                
                <!-- MAYBANK -->
                <ol v-if="order.faspay_code == 814 || order.faspay_code == 408" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">SMS+Banking <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Klik App Maybank SMS+ Banking</li>
                                        <li>Input Passcode (jika ada)</li>
                                        <li>Pilih media koneksi : SMS , USSD atau Data (lihat indikator koneksi pada pojok kanan atas)</li>
                                        <li>Menu Utama, klik icon Transfer</li>
                                        <li>Pilih Virtual Account</li>
                                        <li>Pada kolom Rekening Sumber, masukkan rekening sumber (Pada media koneksi USSD tidak diperlukan)</li>
                                        <li>Pada kolom Jumlah, Masukkan jumlah yang akan di bayarkan</li>
                                        <li>Pada kolom No Ref/Berita, masukkan No Ref/Berita</li>
                                        <li>Pada kolom Rekening Tujuan, Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi</li>
                                        <li>Klik tombol Kirim</li>
                                        <li>Masukkan PIN yang dikirim melalui SMS</li>
                                        <li>Transaksi selesai</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Aplikasi M2U <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Login Aplikasi M2U Menggunakan Fingerprint Atau Masukkan Password</li>
                                        <li>Pilih Transfer</li>
                                        <li>Pilih Virtual Account</li>
                                        <li>Pilih Sumber Tabungan</li>
                                        <li>Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi, Klik LANJUT</li>
                                        <li>Masukkan Jumlah Transfer, Klik PROSES</li>
                                        <li>Klik KONFIRMASI & MINTA TAC</li>
                                        <li>Masukkan SMS Token/TAC, Klik OK</li>
                                        <li>Selesai</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Maybank <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukan PIN ATM Anda</li>
                                        <li>Pilih Transfer</li>
                                        <li>Pilih Virtual Account</li>
                                        <li>Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi</li>
                                        <li>Pilih BENAR</li>
                                        <li>Pilih YA</li>
                                        <li>Selesai</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Bank Lain <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan PIN ATM Anda</li>
                                        <li>Pilih Menu Transaksi Lainnya</li>
                                        <li>Pilih Menu Ke Rek Bank Lain</li>
                                        <li>Masukkan Kode Bank Maybank (016) Kemudian Tekan Benar</li>
                                        <li>Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi, Dan Tekan Benar</li>
                                        <li>Masukkan Jumlah Pembayaran Sesuai Dengan Yang Ditagihkan Dalam Halaman Konfirmasi</li>
                                        <li>Pilih Benar Untuk Menyetujui Transaksi Tersebut</li>
                                        <li>Selesai</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Internet atau Mobile Banking Bank Lain <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Buka Aplikasi Internet Banking Anda</li>
                                        <li>Masukkan ID Dan Password Anda</li>
                                        <li>Pilih Menu Transaksi Antarbank</li>
                                        <li>Pilih Nama Bank: Maybank (016), Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi, Nominal Transfer Dan Informasi Lainnya</li>
                                        <li>Pastikan Detail Tagihan Anda Sudah Benar, Kemudian Pilih OK Atau Lanjut</li>
                                        <li>Masukkan PIN Transaksi Anda</li>
                                        <li>Pembayaran Selesai</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </li>

                </ol>
                
                <!-- PERMATA -->
                <ol v-if="order.faspay_code == 402" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Permata <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukan PIN</li>
                                        <li>Pilih menu TRANSAKSI LAINNYA</li>
                                        <li>Pilih menu PEMBAYARAN</li>
                                        <li>Pilih menu PEMBAYARAN LAINNYA</li>
                                        <li>Pilih VIRTUAL ACCOUNT</li>{{ order.faspay_trx_id }}
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT (Contoh: 8985331268571047)</li>
                                        <li>Jumlah yang harus dibayar dan nomor rekening akan muncul pada halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih BENAR</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Prima <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan PIN</li>
                                        <li>Pilih menu TRANSFER</li>
                                        <li>Pilih menu TRANSFER KE BANK LAIN</li>
                                        <li>Masukkan KODE PermataBank (013) lalu tekan BENAR</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT (Contoh: 8985331268571047)</li>
                                        <li>Halaman konfirmasi pembayaran akan muncul. Jika informasi sudah benar, pilih BENAR <br>Note: Jika jumlah nominal tidak sesuai dengan tagihan akan menyebabkan transaksi gagal.</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Bersama <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukan PIN</li>
                                        <li>Pilih menu TRANSFER</li>
                                        <li>Pilih menu TRANSFER KE BANK LAIN</li>
                                        <li>Masukkan KODE PermataBank (013) + masukkan NOMOR VIRTUAL ACCOUNT (Contoh: 0138985331268571047)</li>
                                        <li>Halaman konfirmasi pembayaran akan muncul. Jika informasi sudah benar, pilih BENAR <br> Note: Jika jumlah nominal tidak sesuai dengan tagihan akan menyebabkan transaksi gagal.</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Permata Mobile <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Buka aplikasi PERMATAMOBILE</li>
                                        <li>Masukkan USER ID & PASSWORD</li>
                                        <li>Pilih BAYAR TAGIHAN</li>
                                        <li>Pilih VIRTUAL ACCOUNT</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT (Contoh: 8985331268571047)</li>
                                        <li>Pilih REKENING</li>
                                        <li>Masukkan NOMINAL PEMBAYARAN</li>
                                        <li>Muncul konfirmasi pembayaran</li>
                                        <li>Masukkan OTENTIKASI TRANSAKSI (TOKEN)</li>
                                        <li>Transaksi Selesai</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Permata Net <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Buka website PermataNet : https://new.permatanet.com</li>
                                        <li>Masukan USER ID & PASSWORD</li>
                                        <li>Masukan KODE KEAMANAN (CAPTCHA)</li>
                                        <li>Pilih PEMBAYARAN TAGIHAN</li>
                                        <li>Pilih VIRTUAL ACCOUNT</li>
                                        <li>Pilih REKENING</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT (Contoh: 8985331268571047)</li>
                                        <li>Masukkan NOMINAL PEMBAYARAN</li>
                                        <li>Muncul KONFIRMASI PEMBAYARAN</li>
                                        <li>Masukan OTENTIKASI TRANSAKSI (TOKEN)</li>
                                        <li>Transaksi selesai</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </li>

                </ol>
                
                <!-- BCA Virtual Account -->
                <ol v-if="order.faspay_code == 702" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM BCA<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan KARTU ATM BCA & PIN</li>
                                        <li>Pilih TRANSAKSI LAINNYA</li>
                                        <li>Pilih TRANSFER</li>
                                        <li>Pilih ke BCA VIRTUAL ACCOUNT</li>
                                        <li>Masukkan NOMOR BCA VIRTUAL ACCOUNT</li>
                                        <li>Masukkan JUMLAH yang ingin dibayarkan lalu tekan BENAR</li>
                                        <li>Validasi pembayaran anda, tekan YA untuk melanjutkan pembayaran</li>
                                        <li>Pembayaran SELESAI</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">KlikBCA Individual<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Lakukan LOG IN pada aplikasi KlikBCA Individual</li>
                                        <li>Masukkan USER ID dan PIN</li>
                                        <li>Pilih TRANSFER DANA</li>
                                        <li>Pilih TRANSFER KE BCA VIRTUAL ACCOUNT</li>
                                        <li>Masukkan NOMOR BCA VIRTUAL ACCOUNT atau pilih dari daftar transfer</li>
                                        <li>Masukkan JUMLAH yang ingin dibayarkan</li>
                                        <li>Validasi pembayaran, Masukkan TOKEN lalu tekan KIRIM untuk melanjutkan transaksi</li>
                                        <li>Pembayaran SELESAI</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">KlikBCA Bisnis<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Lakukan LOG IN pada aplikasi KlikBCA Bisnis</li>
                                        <li>Pilih TRANSFER DANA > DAFTAR TRANSFER > TAMBAH</li>
                                        <li>Masukkan NOMOR BCA VIRTUAL ACCOUNT kemudian KIRIM</li>
                                        <li>Pilih TRANSFER DANA > TRANSFER KE BCA VIRTUAL ACCOUNT</li>
                                        <li>Pilih REKENING SUMBER DANA dan REKENING BCA VIRTUAL ACCOUNT TUJUAN</li>
                                        <li>Masukkan JUMLAH yang ingin dibayarkan</li>
                                        <li>Validasi pembayaran</li>
                                        <li>Pilih TRANSFER DANA > OTORISASI TRANSAKSI lalu pilih TRANSAKSI yang ingin DIOTORISASI
                                            <ul>
                                                <li>Single Otorisasi
                                                    <ol>
                                                        <li>Login User RELEASER</li>
                                                        <li>Tandai transaksi pada tabel transaksi yang belum DIOTORISASI, pilih SETUJU</li>
                                                        <li>Ikuti langkah selanjutnya sampai selesai</li>
                                                    </ol>
                                                </li>
                                                <li>Multi Otorisasi
                                                    <ol>
                                                        <li>Login User APPROVER</li>
                                                        <li>Tandai transaksi pada tabel approver, pilih SETUJU</li>
                                                        <li>Login User RELEASER</li>
                                                        <li>Tandai transaksi pada tabel transaksi yang belum di OTORISASI, pilih SETUJU</li>
                                                        <li>Ikuti langkah selanjutnya sampai selesai</li>
                                                    </ol>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Pembayaran SELESAI</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">m-BCA (BCA Mobile)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Lakukan LOG IN pada aplikasi BCA Mobile</li>
                                        <li>Pilih m-BCA lalu masukkan KODE AKSES m-BCA</li>
                                        <li>Pilih M-TRANSFER</li>
                                        <li>Pilih BCA VIRTUAL ACCOUNT</li>
                                        <li>Masukkan NOMOR BCA VIRTUAL ACCOUNT atau pilih dari daftar transfer</li>
                                        <li>Masukkan JUMLAH yang ingin dibayarkan</li>
                                        <li>Masukkan PIN m-BCA</li>
                                        <li>Pembayaran SELESAI</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">m-BCA (Menu Simcard)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Lakukan LOG IN pada aplikasi BCA Mobile</li>
                                        <li>Pilih m-BCA lalu masukkan KODE AKSES m-BCA</li>
                                        <li>Pilih M-PAYMENT</li>
                                        <li>Pilih LAINNYA</li>
                                        <li>Ketik TVA pada nama PT, lalu OK</li>
                                        <li>Masukkan NOMOR BCA VIRTUAL ACCOUNT kemudian masukkan PIN</li>
                                        <li>Validasi pembayaran dan masukkan JUMLAH PEMBAYARAN</li>
                                        <li>Pembayaran SELESAI</li>
                                    </ol>
                                </div>
                            </div>

                        </div>

                    </li>

                </ol>
                <!-- BANK BNC(NEO) -->
                <ol v-if="order.faspay_code == 718" class="ol-checkout-content__success-detail" >
					<li>
						<div class="ol-product-content-detail__desc-spec">
							<div>
								<h4 class=" ol-product-content-detail__desc-spec-title">
									APLIKASI NEO+<i class="ol-fa fas fa-angle-down" ></i>
								</h4>
								<div class=" ol-product-content-detail__desc-spec-text " style="display: none" >
									<ol>
										<li>Pada Halaman Utama pilih menu transfer</li>
										<li>Masukkan nomor Virtual Account yang didapatkan</li>
										<li>Neo+ akan menampilkan halaman konfirmasi yang berisikan nomor Virtual Account, nama Virtual Account dan nominal transaksi pembayaran</li>
										<li>Lakukan konfirmasi apabila detail transaksi sudah benar</li>
										<li>Masukkan PIN anda di Neo+</li>
										<li>Transaksi selesai. Neo+ akan menampilkan status transaksi pembayaran yang berhasil</li>
									</ol>
								</div>
							</div>
						</div>
					</li>
				</ol>
                <!-- BNI Virtual Account -->
                <ol v-if="order.faspay_code == 801" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM BNI<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan KMasukkan Kartu Anda</li>
                                        <li>Pilih BAHASA</li>
                                        <li>Masukkan PIN ATM Anda</li>
                                        <li>Pilih 'MENU LAINNYA'</li>
                                        <li>Pilih 'TRANSFER'</li>
                                        <li>Pilih JENIS REKENING yang akan Anda gunakan (Contoh; 'Dari Rekening Tabungan')</li>
                                        <li>Pilih 'VIRTUAL ACCOUNT BILLING'</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT Anda (contoh: &nbsp;{{ order.faspay_trx_id }})</li>
                                        <li>Tagihan yang harus dibayarkan akan muncul pada LAYAR KONFIRMASI</li>
                                        <li>Konfirmasi, apabila telah sesuai, LANJUTKAN TRANSAKSI</li>
                                        <li>Transaksi Anda telah selesai</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">mBanking BNI<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Akses BNI Mobile Banking dari handphone kemudian masukkan USER ID dan PASSWORD</li>
                                        <li>Pilih menu "TRANSFER"</li>
                                        <li> Pilih menu "VIRTUAL ACCOUNT BILLING" kemudian pilih rekening debet</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT Anda (contoh: &nbsp;{{ order.faspay_trx_id }}) pada menu "INPUT BARU"</li>
                                        <li>Tagihan yang harus dibayarkan akan muncul pada LAYAR KONFIRMASI</li>
                                        <li>Konfirmasi transaksi dan masukkan PASSWORD TRANSAKSI</li>
                                        <li>Pembayaran Anda Telah Berhasil</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">iBank BNI<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Ketik alamat https://ibank.bni.co.id kemudian klik "Enter"</li>
                                        <li>Masukkan User ID dan Password</li>
                                        <li>Pilih menu "Transfer"</li>
                                        <li>Pilih "VIRTUAL ACCOUNT BILLING"</li>
                                        <li>Kemudian masukan NOMOR VIRTUAL ACCOUNT Anda (contoh: &nbsp;{{ order.faspay_trx_id }}) yang hendak dibayarkan. Lalu pilih rekening debet yang akan digunakan. Kemudian tekan "lanjut"</li>
                                        <li>Kemudin tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
                                        <li>Masukkan Kode Otentikasi Token</li>
                                        <li>Pembayaran Anda telah berhasil</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Cabang atau Outlet BNI (Teller)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Kunjungi Kantor Cabang/outlet BNI terdekat</li>
                                        <li>Informasikan kepada Teller, bahwa ingin melakukan pembayaran 'VIRTUAL ACCOUNT BILLING'</li>
                                        <li>Serahkan NOMOR VIRTUAL ACCOUNT Anda kepada Teller</li>
                                        <li>Teller melakukan KONFIRMASI kepada Anda</li>
                                        <li>Teller MEMPROSES TRANSAKSI</li>
                                        <li>Apabila transaksi SUKSES anda akan menerima bukti pembayaran dari Teller tersebut</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">m-BCA (Menu Simcard)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Kunjungi Agen46 terdekat (warung/took/kios dengan tulisan Agen46)</li>
                                        <li>Informasikan kepada Agen46, bahwa ingin melakukan pembayaran "VIRTUAL ACCOUNT BILLING"</li>
                                        <li>Serahkan NOMOR VIRTUAL ACCOUNT Anda kepada Agen46</li>
                                        <li>Agen46 melakukan KONFIRMASI kepada Anda</li>
                                        <li>Agen46 Proses Transaksi</li>
                                        <li>Apabila transaksi SUKSES anda akan menerima bukti pembayaran dari Agen46 tersebut</li>
                                    </ol>
                                </div>
                            </div>

                        </div>

                    </li>

                </ol>
                
                <!-- Danamon Virtual Account -->
                <ol v-if="order.faspay_code == 708" class="ol-checkout-content__success-detail">
                    <li>
                        <div class="ol-product-content-detail__desc-spec">

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Danamon(Pembayaran Open/Partial)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan KARTU ATM</li>
                                        <li>Pilih BAHASA yang akan digunakan</li>
                                        <li>Masukkan PIN, pilih LANJUTKAN</li>
                                        <li>Pada layar pengumuman Danamon, pilih LANJUTKAN</li>
                                        <li>Pada menu PILIH JUMLAH UANG ATAU JENIS TRANSAKSI, pilih PEMBAYARAN</li>
                                        <li>Pada menu PEMBAYARAN, pilih LAINNYA</li>
                                        <li>Pada menu PEMBAYARAN LANJUTAN, pilih VIRTUAL ACCOUNT</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOMasukkan NOMOR VIRTUAL ACCOUNT yang akan dibayarkan, setelah itu pilih BENAR</li>
                                        <li>Setelah muncul konfirmasi data pembayaran, MASUKKAN NOMINAL yang akan dibayar</li>
                                        <li>Pada layar konfirmasi pembayaran virtual account, pastikan data yang tertera sudah benar</li>
                                        <li>Pilih YA untuk membayar</li>
                                        <li>Transaksi selesai, simpan struk pembayaran</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Danamon (Closed Payment)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan KARTU</li>
                                        <li>Pilih BAHASA yang akan digunakan</li>
                                        <li>Masukkan PIN, pilih LANJUTKAN</li>
                                        <li>Pada layar pengumuman Danamon, pilih LANJUTKAN</li>
                                        <li>Pada menu PILIH JUMLAH UANG ATAU JENIS TRANSAKSI, pilih PEMBAYARAN</li>
                                        <li>Pada menu PEMBAYARAN, pilih LAINNYA</li>
                                        <li>Pada menu PEMBAYARAN LANJUTAN, pilih VIRTUAL ACCOUNT</li>
                                        <li>Masukkan NOMOR VIRTUAL ACCOUNT yang akan dibayarkan, setelah itu pilih BENAR</li>
                                        <li>Setelah muncul konfirmasi data pembayaran, pastikan data yang tertera sudah benar. Pilih YA untuk membayar</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">ATM Bank Lain<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Setelah memasukkan ATM & PIN, pilih menu PINDAH BUKU ke Bank Lain atau ONLINE TRANSFER ke Bank Lain</li>
                                        <li>Masukkan KODE ‘011’ (kode Danamon) diikuti dengan NOMOR VIRTUAL ACCOUNT yang akan dibayarkan, kemudian pilih PROSES/LANJUTKAN</li>
                                        <li>Masukkan NOMINAL PEMBAYARAN, kemudian pilih PROSES/LANJUTKAN</li>
                                        <li>Biarkan kosong pada pilihan input NOMOR REFERENSI, langsung pilih PROSES/LANJUTKAN</li>
                                        <li>Pilih SUMBER REKENING pendebetan Anda</li>
                                        <li>Pastikan data yang tertera sudah benar, pilih PROSES/LANJUTKAN untuk melakukan pembayaran</li>
                                        <li>Transaksi selesai, simpan struk pembayaran Anda</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Danamon Mobile Banking D-Bank (Open/Partial Payment)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan USER ID & PASSWORD, pilih LOGIN</li>
                                        <li>Pilih PEMBAYARAN</li>
                                        <li>Gulung layar kebawah, pilih VIRTUAL ACCOUNT</li>
                                        <li>
                                            <ol>
                                                <li>Masukkan NOMOR VA untuk PENDAFTARAN VA baru, pilih Ajukan. Setelah itu ikuti panduan</li>
                                                <li>Masukkan NOMINAL yang ingin ditransfer</li>
                                            </ol>
                                        </li>
                                        <li>Pastikan kebenaran pengisian data. Masukkan mPIN untuk mengkonfirmasi pembayaran, pilih KONFIRMASI. Tunggu sampai layar menunjukkan konfirmasi ‘Transaksi Berhasil’. Selesai.</li>
                                    </ol>
                                </div>
                            </div>

                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title">Danamon Mobile Banking D-Bank (Closed Payment)<i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    <ol>
                                        <li>Masukkan USER ID & PASSWORD, pilih LOGIN</li>
                                        <li>Pilih PEMBAYARAN</li>
                                        <li>Gulung layar kebawah, pilih VIRTUAL ACCOUNT</li>
                                        <li>
                                            <ol>
                                                <li>Jika NOMOR VA BELUM PERNAH disimpan, pilih ‘+TAMBAH BILLER BARU’ untuk memasukkan NOMOR VA, setelah itu ikuti petunjuk pada poin 5.A</li>
                                                <li>Jika NOMOR VA SUDAH DISIMPAN sebelumnya, pilih NOMOR VA pada DAFTAR REKENING TUJUAN Favorit, setelah itu ikuti panduan pada poin 5.B</li>
                                            </ol>
                                        </li>
                                        <li>
                                            <ol>
                                                <li>Masukkan NOMOR VA untuk PENDAFTARAN VA baru, pilih Ajukan. Setelah itu ikuti panduan 5.B</li>
                                                <li>Pastikan kebenaran data yang tertera pada layar. Masukkan mPIN untuk mengkonfirmasi pembayaran, pilih KONFIRMASI. Tunggu sampai layar menunjukkan konfirmasi ‘Transaksi Berhasil’. Selesai.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                        </div>

                    </li>

                </ol>

                <!-- BRI Virtual Account -->
				<ol v-if="order.faspay_code == 800" class="ol-checkout-content__success-detail">
				<li>
					<div class="ol-product-content-detail__desc-spec">
						<div>
							<h4 class="ol-product-content-detail__desc-spec-title"> ATM BRI <i class="ol-fa fas fa-angle-down"></i>
							</h4>
							<div class="ol-product-content-detail__desc-spec-text" style="display: none">
							<ol>
								<li>Masukkan KARTU ATM BRI & PIN</li>
								<li>Pilih TRANSAKSI LAINNYA</li>
								<li>Pilih TRANSFER</li>
								<li>Pilih ke BRI VIRTUAL ACCOUNT</li>
								<li> Masukkan NOMOR BRI VIRTUAL ACCOUNT </li>
								<li> Masukkan JUMLAH yang ingin dibayarkan lalu tekan BENAR </li>
								<li> Validasi pembayaran anda, tekan YA untuk melanjutkan pembayaran </li>
								<li>Pembayaran SELESAI</li>
							</ol>
							</div>
						</div>
					</div>
				</li>
				</ol>

				<!-- BSI Virtual Account -->
				<ol v-if="order.faspay_code == 723" class="ol-checkout-content__success-detail">
				<li>
					<div class="ol-product-content-detail__desc-spec">
						<div>
							<h4 class="ol-product-content-detail__desc-spec-title"> ATM BSI <i class="ol-fa fas fa-angle-down"></i>
							</h4>
							<div class="ol-product-content-detail__desc-spec-text" style="display: none">
							<ol>
								<li>Masukkan KARTU ATM BSI & PIN</li>
								<li>Pilih TRANSAKSI LAINNYA</li>
								<li>Pilih TRANSFER</li>
								<li>Pilih ke BSI VIRTUAL ACCOUNT</li>
								<li> Masukkan NOMOR BSI VIRTUAL ACCOUNT </li>
								<li> Masukkan JUMLAH yang ingin dibayarkan lalu tekan BENAR </li>
								<li> Validasi pembayaran anda, tekan YA untuk melanjutkan pembayaran </li>
								<li>Pembayaran SELESAI</li>
							</ol>
							</div>
						</div>
					</div>
				</li>
				</ol>

				<!-- BTN Virtual Account -->
				<ol v-if="order.faspay_code == 837" class="ol-checkout-content__success-detail">
				<li>
					<div class="ol-product-content-detail__desc-spec">
						<div>
							<h4 class="ol-product-content-detail__desc-spec-title"> ATM BTN <i class="ol-fa fas fa-angle-down"></i>
							</h4>
							<div class="ol-product-content-detail__desc-spec-text" style="display: none">
							<ol>
								<li>Masukkan KARTU ATM BTN & PIN</li>
								<li>Pilih TRANSAKSI LAINNYA</li>
								<li>Pilih TRANSFER</li>
								<li>Pilih ke BTN VIRTUAL ACCOUNT</li>
								<li> Masukkan NOMOR BTN VIRTUAL ACCOUNT </li>
								<li> Masukkan JUMLAH yang ingin dibayarkan lalu tekan BENAR </li>
								<li> Validasi pembayaran anda, tekan YA untuk melanjutkan pembayaran </li>
								<li>Pembayaran SELESAI</li>
							</ol>
							</div>
						</div>
					</div>
				</li>
				</ol>
            </div>
            <div class="ol-checkout-nav">
                <router-link :to="'/profile/order/'+order.ordersn" class="ol-btn">Lihat Transaksi </router-link>
            </div>
        </div>
        <div class="ol-addcart" id="copy_url" hidden="true">
            <i class=""></i> Tautan Berhasil tersimpan
        </div>
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import helpme from '@/util/helpme'
export default {
    name:"ProfilePay",
    data(){
        return {
            order:null,
        }
    },
    methods:{
        copyNumber(selector) {
            let value = $(selector).select().text();
            navigator.clipboard.writeText(value).then(function () {
                $('#copy_url').prop('hidden',false);
                setTimeout(() => { $('#copy_url').prop('hidden',true); }, 1500);
            }, function () {
                // alert('Failure to copy. Check permissions for clipboard')
            });

        },
        jq(){
            
            // $("a#a-footer-to-top").trigger("click");
            // $('html, body').animate({ scrollTop: $("h1.ol-header-checkout__logo").offset().top }, 500)
            $("h4.ol-product-content-detail__desc-spec-title").click(function(){
                $(this).parent().find(".ol-product-content-detail__desc-spec-text").slideToggle("fast");
            });

            

        },
    },
    created(){
        let retrieve  = JSON.parse(localStorage.getItem("detailOrder"));
            this.order = retrieve.order;
    },
    mounted(){
        this.jq();
    }
}
</script>

<style>

</style>